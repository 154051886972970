.container {
	@media screen and (min-height: 768px) {
		margin-top: 20px;
	}
}

.task-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.3s ease;
}

.expanded button {
	transform: rotate(90deg);
}

.expanded .task-list {
	max-height: none;
}

.responsive-input {
	@media screen and (min-width: 768px) {
		display: block;
	}

	@media screen and (max-width: 767px) {
		display: none;
	}
}

.task-item {
	display: grid;
	width: 100%;
	grid-template-columns: 80px 400px 150px 150px 150px 160px 140px;

	@media screen and (max-width: 1405px) {
		grid-template-columns: 76px 390px 145px 145px 150px 165px 130px;
	}

	@media screen and (max-width: 1395px) {
		grid-template-columns: 74px 370px 143px 143px 150px 165px 125px
	}

	@media screen and (max-width: 1370px) {
		grid-template-columns: 73px 350px 142px 142px 150px 165px 123px;
	}

	@media screen and (max-width: 1330px) {
		grid-template-columns: 72px 310px 141px 141px 150px 165px 122px;
	}

	@media screen and (max-width: 1310px) {
		grid-template-columns: 72px 290px 140px 140px 150px 165px 120px;
	}

	@media screen and (max-width: 1279px) {
		grid-template-columns: 65px 370px 140px 140px 149px 165px 140px
	}

	@media screen and (max-width: 1250px) {
		grid-template-columns: 64px 350px 140px 140px 149px 165px 135px;
	}

	@media screen and (max-width: 1230px) {
		grid-template-columns: 63px 330px 140px 140px 149px 165px 135px
	}

	@media screen and (max-width: 1200px) {
		grid-template-columns: 62px 300px 140px 140px 149px 165px 135px;
	}

	@media screen and (max-width: 1160px) {
		grid-template-columns: 61px 270px 140px 140px 149px 165px 135px;
	}

	@media screen and (max-width: 1140px) {
		grid-template-columns: 61px 250px 140px 140px 149px 165px 135px;
	}

	@media screen and (max-width: 1120px) {
		grid-template-columns: 61px 240px 140px 140px 149px 165px 120px;
	}

	@media screen and (max-width: 1100px) {
		grid-template-columns: 60px 230px 140px 140px 148px 164px 100px;
	}

	@media screen and (max-width: 1071px) {
		grid-template-columns: 60px 190px 140px 140px 148px 164px 100px;
	}

	@media screen and (max-width: 1040px) {
		grid-template-columns: 50px 190px 140px 140px 148px 164px 100px;
	}

	@media screen and (max-width: 1023px) {
		grid-template-columns: 60px 330px 100px 110px 90px 130px 120px;
	}

	@media screen and (max-width: 1000px) {
		grid-template-columns: 57px 310px 100px 110px 90px 130px 120px;
	}

	@media screen and (max-width: 970px) {
		grid-template-columns: 55px 300px 100px 107px 90px 125px 115px;
	}

	@media screen and (max-width: 950px) {
		grid-template-columns: 53px 290px 98px 105px 90px 120px 100px;
	}

	@media screen and (max-width: 905px) {
		grid-template-columns: 53px 270px 88px 105px 90px 110px 95px;
	}

	@media screen and (max-width: 870px) {
		grid-template-columns: 48px 270px 85px 105px 90px 100px 90px;
	}

	@media screen and (max-width: 850px) {
		grid-template-columns: 43px 260px 79px 100px 60px 110px 110px;
	}

	@media screen and (max-width: 820px) {
		grid-template-columns: 41px 250px 75px 95px 55px 105px 105px;
	}

	@media screen and (max-width: 800px) {
		grid-template-columns: 39px 240px 71px 95px 55px 100px 100px;
	}

	@media screen and (max-width: 780px) {
		grid-template-columns: 45px 220px 71px 90px 55px 105px 105px;
	}

	@media screen and (max-width: 767px) {
		grid-template-columns: 46px 270px 60px 60px 60px 105px 105px;
	}

	@media screen and (max-width: 750px) {
		grid-template-columns: 46px 250px 60px 60px 60px 105px 105px;
	}

	@media screen and (max-width: 720px) {
		grid-template-columns: 45px 230px 60px 60px 60px 100px 100px;
	}

	@media screen and (max-width: 690px) {
		grid-template-columns: 45px 200px 60px 60px 60px 100px 100px;
	}

	@media screen and (max-width: 660px) {
		grid-template-columns: 44px 180px 60px 60px 60px 100px 100px;
	}

	@media screen and (max-width: 639px) {
		grid-template-columns: 42px 180px 60px 60px 60px 100px 90px;
	}

	@media screen and (max-width: 620px) {
		grid-template-columns: 42px 180px 55px 55px 55px 90px 90px;
	}

	@media screen and (max-width: 590px) {
		grid-template-columns: 42px 170px 55px 55px 55px 90px 90px;
	}

	@media screen and (max-width: 570px) {
		grid-template-columns: 38px 150px 50px 50px 50px 90px 90px;
	}

	@media screen and (max-width: 540px) {
		grid-template-columns: 37px 140px 50px 50px 50px 85px 85px;
	}

	@media screen and (max-width: 520px) {
		grid-template-columns: 35px 125px 50px 50px 50px 85px 85px;
	}

	@media screen and (max-width: 500px) {
		grid-template-columns: 32px 120px 50px 50px 50px 80px 80px;
	}

	@media screen and (max-width: 479px) {
		grid-template-columns: 32px 130px 45px 45px 45px 50px 80px;
	}

	@media screen and (max-width: 465px) {
		grid-template-columns: 29px 120px 45px 45px 45px 40px 80px;
	}

	@media screen and (max-width: 445px) {
		grid-template-columns: 28px 120px 40px 40px 40px 40px 80px;
	}

	@media screen and (max-width: 430px) {
		grid-template-columns: 27px 150px 40px 40px 40px 40px 60px;
	}

	@media screen and (max-width: 415px) {
		grid-template-columns: 27px 140px 40px 40px 40px 40px 60px;
	}

	@media screen and (max-width: 390px) {
		grid-template-columns: 25px 130px 40px 40px 40px 40px 60px;
	}

	@media screen and (max-width: 380px) {
		grid-template-columns: 28px 100px 40px 40px 40px 30px 65px;
	}

	@media screen and (max-width: 350px) {
		grid-template-columns: 25px 85px 45px 40px 35px 25px 75px;
	}

	@media screen and (max-width: 330px) {
		grid-template-columns: 20px 105px 45px 40px 35px 20px 33px;
	}

	@media screen and (max-width: 310px) {
		grid-template-columns: 20px 95px 45px 40px 35px 20px 33px;
	}

	@media screen and (max-width: 300px) {
		grid-template-columns: 20px 85px 45px 48px 34px 18px 30px;
	}
}

.task-item::before {
	content: '';
	background-color: black;
	height: 1px;
	position: absolute;
	left: 5%;
	opacity: 0.1;
	top: -2px;
	width: 90%;
}

.task-item:first-child::before {
	display: none;
}

.retard-tasks {
	color: #d32f2f;
	background-color: #ffebee;
}

.today-tasks {
	color: #4caf50;
	background-color: #e8f5e9;
}

.next-week-tasks {
	color: #673ab7;
	background-color: #ede7f6;
}

.Demain {
	color: #ffc107;
	background-color: #fff9c4;
}

.Lundi {
	color: #e91e63;
	background-color: #fce4ec;
}

.Mardi {
	color: #9c27b0;
	background-color: #f3e5f5;
}

.Mercredi {
	color: #673ab7;
	background-color: #ede7f6;
}

.Jeudi {
	color: #3f51b5;
	background-color: #e8eaf6;
}

.Vendredi {
	color: #2196f3;
	background-color: #e3f2fd;
}

.Samedi {
	color: #03a9f4;
	background-color: #e1f5fe;
}

.Dimanche {
	color: #00bcd4;
	background-color: #e0f7fa;
}

.this-week-tasks {
	color: #2196f3;
	background-color: #e3f2fd;
}

.this-month-tasks {
	color: #9c27b0;
	background-color: #f3e5f5;
}

.this-year-tasks {
	color: #f44336;
	background-color: #ffebee;
}

.becoming-tasks {
	color: #607d8b;
	background-color: #eceff1;
}

.next-weekend-tasks {
	color: #ff9800;
	background-color: #fff3e0;
}

.this-weekend-tasks {
	color: #4caf50;
	background-color: #e8f5e9;
}

.next-month-tasks {
	color: #9c27b0;
	background-color: #f3e5f5;
}

.next-year-tasks {
	color: #f44336;
	background-color: #ffebee;
}

.faire {
	color: #1976d2;
	background-color: #bbdefb;
}

.cours {
	color: #0d47a1;
	background-color: #e3f2fd;
}

.Terminé {
	color: #388e3c;
	background-color: #c8e6c9;
}

.Archivé {
	color: #616161;
	background-color: #f5f5f5;
}

.Faible {
	color: #0288d1;
	background-color: #b3e5fc;
}

.Moyenne {
	color: #c2185b;
	background-color: #f8bbd0;
}

.Haute {
	color: #c2185b;
	background-color: #f8bbd0;
}

.Urgent {
	color: #d32f2f;
	background-color: #ffcdd2;
}

.avatarLetterAssigned {
	color: #eaefff;
	font-size: 1rem;
}

#avatarLetterNotif {
	color: #eaefff;
	font-size: 1.5rem;
}

.ellipsis {
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.archive-icon-item {
	position: absolute;
	left: 0;
	top: 50%;
}

.archive-icon-urgent {
	position: absolute;
	right: 15px;
	top: 50%;
}

.archive-icon {
	border: 2px solid #4b5563;
	border-radius: 50%;
	cursor: pointer;
	padding: 4px;
	transform: translateY(-50%);
	transition: all 0.3s ease-in-out;

	&:hover,
	&:focus {
		background-color: #f0fff4;
		border-color: #10b981;
		color: #4ade80;

		svg {
			stroke: #10b981;
		}
	}

	svg {
		height: 20px;
		stroke: #4b5563;
		width: 20px;

		@media screen and (max-width: 800px) {
			height: 15px;
			width: 15px;
		}

		@media screen and (max-width: 479px) {
			height: 10px;
			width: 10px;
		}

		@media screen and (max-width: 430px) {
			height: 7px;
			width: 7px;
		}
	}

	@media screen and (max-width: 300px) {
		display: none;
	}
}

.delete-icon {
	border: 2px solid #9ca3af;
	border-radius: 50%;
	cursor: pointer;
	position: absolute;
	left: 0;
	top: 50%;
	padding: 4px;
	transform: translateY(-50%);
	transition: all 0.3s ease-in-out;

	&:hover,
	&:focus {
		background-color: #fef2f2;
		border-color: #f87171;
		color: #ef4444;

		svg {
			stroke: #f87171;
		}
	}

	svg {
		height: 20px;
		stroke: #9ca3af;
		width: 20px;

		@media screen and (max-width: 800px) {
			height: 15px;
			width: 15px;
		}

		@media screen and (max-width: 479px) {
			height: 10px;
			width: 10px;
		}

		@media screen and (max-width: 430px) {
			height: 7px;
			width: 7px;
		}
	}

	@media screen and (max-width: 300px) {
		display: none;
	}
}

// Tooltip

.tooltip-container {
	opacity: 0;
	position: fixed;
	top: 1rem;
	right: 6rem;
	animation: slideDown 0.2s ease-in-out 1s forwards,
		fadeOut 0.2s ease-in-out 10s forwards;

	&.closing {
		animation: fadeOutClose 0.2s ease-in-out forwards;
	}

	@keyframes slideDown {
		0% {
			transform: translateY(-120px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(-120px);
		}
	}

	@keyframes fadeOutClose {
		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(-120px);
		}
	}

	@media (max-width: 1280px) {
		top: 5rem;
	}

	.tooltip-box {
		position: relative;
		background: #f9f871;
		color: #0369a1;
		padding: 1.1rem;
		border-radius: 0.5rem;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		max-width: 300px;
		text-align: left;

		&:before {
			// Arrow at the top of the bubble
			content: '';
			position: absolute;
			top: -8px;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			border-width: 0.5rem;
			border-style: solid;
			border-color: transparent transparent #f9f871 transparent;
		}

		button.close-button {
			position: absolute;
			transform: translateX(50%) translateY(-50%);
			top: 1rem;
			right: 1rem;
			background: transparent;
			border: none;
			font-size: 2.5rem;
			font-weight: 300;
			color: #0369a1;
			cursor: pointer;
		}
	}
}