* {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
	margin: 0;
	padding: 0;
	outline: none;
	list-style: none;
	text-decoration: none;
}

#root {
	background: #eaefff;
	min-height: 100vh;
}

.status-icon::before {
	content: '\f110';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.priority-icon::before {
	content: '\f3fd';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.deadline-icon::before {
	content: '\f073';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.description-icon::before {
	content: '\f15c';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.comments-icon::before {
	content: '\f086';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.workspace-icon::before {
	content: '\f0b1';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.assigned-icon::before {
	content: '\f007';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.email-icon::before {
	content: '\f0e0';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1rem;
}

.workspace-icon::before {
	content: '\f1ad';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.task-icon::before {
	content: '\f0ae';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	width: 30px;
	font-size: 1.1rem;
}

.edit-icon::before {
	content: '\f040';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-size: 1rem;

	@media screen and (max-width: 1024px) {
		font-size: 0.9rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 0.8rem;
	}

	@media screen and (max-width: 540px) {
		font-size: 0.7rem;
	}

	@media screen and (max-width: 450px) {
		font-size: 0.6rem;
	}

	@media screen and (max-width: 330px) {
		font-size: 0.5rem;
	}
}

.search-icon::before {
	content: '\f002';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-size: 1.1rem;

	@media screen and (max-width: 1024px) {
		font-size: 0.9rem;
	}

	@media screen and (max-width: 792px) {
		font-size: 0.8rem;
	}

	@media screen and (max-width: 540px) {
		font-size: 0.7rem;
	}

	@media screen and (max-width: 450px) {
		font-size: 0.6rem;
	}

	@media screen and (max-width: 330px) {
		font-size: 0.5rem;
	}
}

.multi-line-truncate {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.loader {
	border: 7px solid #f3f3f3;
	border-top: 7px solid #171f39;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 1.7s linear infinite;
}

.loader-task {
	border: 5px solid #f3f3f3;
	border-top: 5px solid #3498db;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1.7s linear infinite;
}

.loader-create {
	border: 5px solid #f3f3f3;
	border-top: 5px solid #3498db;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1.7s linear infinite;
}

.loader-edit {
	border: 2px solid #f3f3f3;
	border-top: 2px solid #3498db;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	animation: spin 1.7s linear infinite;
}

.loader-delete {
	border: 2px solid #f3f3f3;
	border-top: 2px solid #db3434;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	animation: spin 1.7s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.block-icon,
.delete-icon {
	background: transparent;
	border: none;
	cursor: pointer;
	color: #555;
}

.block-icon:hover,
.delete-icon:hover {
	color: #FF0000;
}

@keyframes slideIn {
	from {
		transform: translateX(-50%) translateY(-100%);
		opacity: 0;
	}

	to {
		transform: translateX(-50%) translateY(0);
		opacity: 1;
	}
}

@keyframes slideOut {
	from {
		transform: translateX(-50%) translateY(0);
		opacity: 1;
	}

	to {
		transform: translateX(-50%) translateY(-100%);
		opacity: 0;
	}
}

.modal-enter {
	animation: slideIn 0.3s forwards;
}

.modal-exit {
	animation: slideOut 0.3s forwards;
}

@keyframes slideRightToLeft {
	from {
		transform: translateX(0);
		opacity: 0;
	}

	to {
		transform: translateX(-100%);
		opacity: 1;
	}
}

@keyframes slideLeftToRight {
	from {
		transform: translateX(-100%);
		opacity: 1;
	}

	to {
		transform: translateX(0);
		opacity: 0;
	}
}

.modal-enter-2 {
	animation: slideRightToLeft 0.2s forwards;
}

.modal-exit-2 {
	animation: slideLeftToRight 0.2s forwards;
}

@keyframes slideInReverse {
	from {
		transform: translateX(-50%) translateY(100%);
		opacity: 0;
	}

	to {
		transform: translateX(-50%) translateY(0);
		opacity: 1;
	}
}

@keyframes slideOutReverse {
	from {
		transform: translateX(-50%) translateY(0);
		opacity: 1;
	}

	to {
		transform: translateX(-50%) translateY(100%);
		opacity: 0;
	}
}

@keyframes slideRightToLeftReverse {
	from {
		transform: translateX(0);
		opacity: 1;
	}

	to {
		transform: translateX(-150%);
		opacity: 0;
	}
}

@keyframes slideLeftToRightReverse {
	from {
		transform: translateX(-150%);
		opacity: 0;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

.modal-enter-2 {
	animation: slideRightToLeft 0.2s forwards;
}

.modal-exit-2 {
	animation: slideLeftToRight 0.2s forwards;
}

@keyframes slideDownNotif {
	0% {
		opacity: 0;
		transform: translateY(-10%);
		max-height: 0;
		display: none;
	}

	20% {
		opacity: 1;
	}

	100% {
		transform: translateY(0%);
		max-height: 500px;
		display: block;
	}
}

@keyframes slideUpNotif {
	0% {
		opacity: 1;
		transform: translateY(0);
		max-height: 500px;
		display: block;
	}

	40% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translateY(-10%);
		max-height: 0;
		display: none;
	}
}

.slideDownNotif {
	animation: slideDownNotif 0.3s forwards;
}

.slideUpNotif {
	animation: slideUpNotif 0.3s forwards;
}

@keyframes slideInNotif {
	from {
		transform: translateY(-100%);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideOutNotif {
	from {
		transform: translateY(0);
		opacity: 1;
	}

	to {
		transform: translateY(-100%);
		opacity: 0;
	}
}

// MESSAGES
.message-bubble {
	max-width: 75%;
	word-wrap: break-word;
}

.notification-badge {
	position: absolute;
	top: 2px;
	right: 2px;
	background-color: red;
	color: white;
	border-radius: 50%;
	padding: 0.2em 0.5em;
	font-size: 0.8em;
}