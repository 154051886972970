.loading-animation {
    animation: fadeInOut 3s infinite;
}

.error-animation {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    border-color: red;
}

@keyframes fadeInOut {

    0% {
        opacity: 0;
        display: none;
    }

    1% {
        opacity: 0;
        display: none;
    }

    99% {
        opacity: 0;
        display: none;
    }

    100% {
        opacity: 1;
        display: block;
    }
}

@keyframes shake {

    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(-10px);
    }

    20% {
        transform: translateX(10px);
    }

    30% {
        transform: translateX(-10px);
    }

    40% {
        transform: translateX(10px);
    }

    50% {
        transform: translateX(-10px);
    }

    60% {
        transform: translateX(10px);
    }

    70% {
        transform: translateX(-10px);
    }

    80% {
        transform: translateX(10px);
    }

    90% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0);
    }

}

// VISITOR BUTTON
.visitor-button {
    background: #FFFAEA;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    color: #171f39;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    outline: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 5px;
}

.visitor-button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.play-icon {
    font-size: 1.7rem;
}

@media (max-width: 1200px) {
    .visitor-button {
        width: 120px;
        height: 120px;
        font-size: 1rem;
        padding-top: 3px;
    }

    .play-icon {
        font-size: 1.4rem;
    }
}

@media (max-width: 1000px) {
    .visitor-button {
        width: 100px;
        height: 100px;
        font-size: 0.9rem;
        padding-top: 3px;
    }

    .play-icon {
        font-size: 1.4rem;
    }
}

@media (max-width: 940px) {
    .visitor-button {
        width: 120px;
        height: 120px;
        font-size: 1rem;
    }

    .play-icon {
        font-size: 1.2rem;
    }

    .visitor-button:hover {
        transform: scale(1.05) translateX(-50%);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }

}