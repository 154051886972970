body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	background: #ffffff;
	color: #171f39;
}

.dashboard-container {
	display: grid;
	gap: 15px;
	grid-template-areas:
		'cal task task'
		'work work cow';
	grid-template-columns: 310px 560px 1fr;
	grid-template-rows: 290px 240px;
	justify-content: center;
	margin-top: 10px;

	@media screen and (min-height: 925px) {
		grid-template-areas:
			'task task'
			'cal cow'
			'work work';
		grid-template-columns: 300px 1fr;
		grid-template-rows: 285px 260px 244px;
	}

	@media (max-width: 1300px) {
		grid-template-areas:
			'cal task task'
			'work work work'
			'cow cow cow';
		grid-template-rows: 290px 240px 240px;
	}

	@media (max-width: 1100px) {
		grid-template-areas:
			'task task'
			'cal cow'
			'work work';
		grid-template-columns: 310px 1fr;
		grid-template-rows: 285px 255px 245px;
	}

	@media (max-width: 768px) {
		grid-template-areas:
			'task task'
			'cal cow'
			'work work';
		grid-template-columns: 310px 1fr;
		grid-template-rows: 285px 255px 245px;
	}

	@media (max-width: 600px) {
		grid-template-areas:
			'task'
			'cal'
			'work'
			'cow';
		grid-template-columns: 1fr;
		grid-template-rows: 280px 255px 250px 220px;
	}
}

.dashboard-card {
	background: #ffffff;
	border-radius: 10px;
	padding: 10px;
	overflow: hidden;
}

.dashboard-card h4 {
	font-size: 1.2rem;
	margin: 0 0 5px;
}

.calendar-container {
	grid-area: cal;
	position: relative;

	@media (max-width: 600px) {
		width: 310px;
		margin: 0 auto;
	}

	@media (max-width: 320px) {
		width: none;
	}
}

.tasks-container {
	grid-area: task;
	padding: 8px 0;
	position: relative;
}

.workspaces-container {
	grid-area: work;
	position: relative;
	padding: 15px 0;
}

.coworkers-container {
	grid-area: cow;
	padding: 10px 0;
}

/************************ Calendar ****************/

#calendar {
	position: absolute;
	top: 0.5rem;
}

.month-year {
	font-size: large;
	font-weight: 500;
	margin-left: 12px;
	padding-top: 0.1rem;
}

.calendar-header,
.calendar-days {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.calendar-day {
	width: 13%;
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 6px 0;
	font-size: 0.9rem;
}

.day-name {
	font-weight: 500;
	opacity: 0.3;
	padding: 6px 0 0;
}

.day {
	align-items: center;
	border-radius: 50%;
	display: flex;
	height: 30px;
	justify-content: center;
	line-height: 30px;
	margin-bottom: -5px;
	width: 30px;
}

.today {
	background: #ffb360;
	color: white;
	font-weight: bold;
}

.other-month {
	color: gray;
}

/************************** URGENT TASKS ************************/

.urgent-task {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 10px 0;
	position: relative;
	transition: 0.1s ease;

	.archive-icon {
		border: 2px solid #4b5563;
		border-radius: 50%;
		cursor: pointer;
		display: none;
		padding: 4px;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		transition: all 0.3s ease-in-out;

		&:hover,
		&:focus {
			background-color: #f0fff4;
			border-color: #10b981;
			color: #4ade80;

			svg {
				stroke: #10b981;
			}
		}

		svg {
			height: 20px;
			stroke: #4b5563;
			width: 20px;

			@media screen and (max-width: 768px) {
				height: 15px;
				width: 15px;
			}

			@media screen and (max-width: 480px) {
				height: 10px;
				width: 10px;
			}

			@media screen and (max-width: 430px) {
				height: 7px;
				width: 7px;
			}
		}
	}

	&:hover,
	&:focus {
		background: #171f39;
		color: white;

		.text-dark-purple-2 {
			color: #eaefff;
		}

		.archive-icon {
			display: block;
		}
	}

	@media screen and (max-width: 768px) {
		.archive-icon {
			display: block;
			right: 5px;
		}
	}
}

.urgent-task-todo {
	display: flex;
	align-items: center;
	height: 40px;
	justify-content: space-between;
	padding-left: 15px;
	margin-right: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media screen and (max-width: 768px) {
		padding-left: 10px;
	}

	@media screen and (max-width: 480px) {
		padding-left: 5px;
	}
}

#check {
	border-radius: 50px;
	border: 1px solid black;
	height: 20px;
	width: 20px;
	margin-right: 10px;

	@media screen and (max-width: 768px) {
		height: 15px;
		width: 15px;
		margin-right: 8px;
	}

	@media screen and (max-width: 480px) {
		height: 10px;
		width: 10px;
		margin-right: 5px;
	}
}

.date-container {
	position: relative;
	min-width: 100px;
	margin-right: 40px;

	@media screen and (max-width: 768px) {
		min-width: 90px;
		margin-right: 35px;
	}

	@media screen and (max-width: 480px) {
		min-width: 80px;
		margin-right: 30px;
	}

	@media screen and (max-width: 430px) {
		min-width: 70px;
		margin-right: 25px;
	}

	@media screen and (max-width: 400px) {
		display: none;
	}
}

.date {
	color: #e1786a;
	font-size: 1rem;
	text-align: left;

	@media screen and (max-width: 768px) {
		font-size: 0.9rem;
	}

	@media screen and (max-width: 480px) {
		font-size: 0.8rem;
	}

	@media screen and (max-width: 350px) {
		font-size: 0.7rem;
	}
}

.date::before {
	content: '';
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #e1786a;
	position: absolute;
	left: -20px;
	top: 50%;
	transform: translateY(-50%);

	@media screen and (max-width: 768px) {
		width: 8px;
		height: 8px;
		left: -15px;
	}

	@media screen and (max-width: 480px) {
		width: 6px;
		height: 6px;
		left: -10px;
	}
}

.task-overdue {
	// background-color: #e1786a;
	background-color: #fdded1;
	color: #98000d;
	font-weight: bold;
}

.task-overdue .date {
	color: #98000d;
}

.no-urgent-tasks {
	position: absolute;
	transform: translate(-50%, -50%);
	left: 50%;
	top: 52%;
	font-size: 1.2rem;
}

/************************** WORKSPACES ************************/

.bg-status-pending {
	background-color: #f9f871;
}

.bg-status-inprogress {
	background-color: #ffb360;
}

.bg-status-completed {
	background-color: #00c6c1;
}

.bg-status-archived {
	background-color: #bbbfe2;
}

.workspace {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 10px 0;
	position: relative;
	transition: 0.1s ease;

	&:hover,
	&:focus {
		background: #ffb360;
	}
}

// CONTACTS
.contact {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2px 0;
	position: relative;
	transition: 0.1s ease;
	cursor: url('../../public/img/paper-plane.png') 12 12, auto;

	&:hover,
	&:focus {
		background: #F9F871;
	}

	&-list {
		cursor: pointer;
	}
}

.blocked {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2px 0;
	position: relative;
	transition: 0.1s ease;

	&:hover,
	&:focus {
		background: #F9F871;
	}

	&-list {
		cursor: pointer;
	}
}

// INVITATIONS

.invitation-list {
	position: relative;

	.accept-icon,
	.decline-icon {
		border: 2px solid #4b5563;
		border-radius: 50%;
		cursor: pointer;
		padding: 4px;
		transition: all 0.3s ease-in-out;

		&:hover,
		&:focus {
			background-color: #f0fff4;

			svg {
				stroke: currentColor;
			}
		}

		svg {
			height: 20px;
			stroke: #4b5563;
			width: 20px;

			@media screen and (max-width: 800px) {
				height: 15px;
				width: 15px;
			}

			@media screen and (max-width: 479px) {
				height: 10px;
				width: 10px;
			}

			@media screen and (max-width: 430px) {
				height: 7px;
				width: 7px;
			}
		}

		@media screen and (max-width: 300px) {
			display: none;
		}
	}

	.accept-icon {
		border-color: #10b981;
		background-color: #fff;

		svg {
			stroke: #67557c;
		}

		&:hover,
		&:focus {
			border-color: #10b981;
			color: #4ade80;
		}
	}

	.decline-icon {
		border-color: #67557c;
		background-color: #fff;

		svg {
			stroke: #67557c;
		}

		&:hover,
		&:focus {
			border-color: #ef4444;
			color: #f87171;
		}
	}
}